<template>
  <div class="order">
    <el-container>
      <el-main class="order-box">
        <div class="header">
          <div class="header-title">我的订单</div>
        </div>
        <div class="content">
          <div class="order-sku-list">
            <div class="order-header">
              <div class="sku-title">
                <span>订单信息</span>
              </div>
              <div class="sku-price">
                <span>数量</span>
              </div>
              <div class="sku-qty">
                <span>支付状态</span>
              </div>
              <div class="sku-promotion">
                <span>持票人/作品信息</span>
              </div>
              <div class="sku-total">
                <span>操作</span>
              </div>
            </div>
            <div
          
              class="store-list"
              v-for="(item, index) in orderList"
              :key="index"
              
            >
              <div class="title">
                <div>
                  <span class="order-no">订单编号：{{ item.order_no }}</span
                  ><span class="order-created-time"
                    >({{ item.create_time }})</span
                  >
                </div>
              </div>
              <div class="store-item">
                <div class="sku-title-col">
                  <div class="sku-title">
                    <div class="sku-image">
                      <img
                        src="https://static.foodtalks.cn/image/ecommerce/product/70c1099e98851b2914572ba7ba256428de44.png"
                        alt="封面图"
                      />
                    </div>
                    <div class="basic-info">
                      <div class="sku-name-wrap">
                        <div class="sku-name one-line">
                          {{ item.order_name }}
                        </div>
                      </div>
                      <div class="sku-description one-line">
                        <!-- 论坛（全体大会+分论坛）、展区、赛事颁奖典礼。 -->
                      </div>
                      <div class="sku-price-qty font-small">
                        <span class="sku-price">5800元</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sku-price-col">
                  <div class="sku-price middle" style="height: 100%">
                    <span>{{ item.ticket_num }}</span>
                  </div>
                </div>
                <div class="sku-qty-col">
                  <div class="sku-price middle" style="height: 100%">
                    <div class="order-status">
                      <div class="">已支付：{{ item.order_amount }}元</div>
                      <div style="text-align: center">实付：{{item.order_actual_pay}}元</div>
                      <div class="order-payment-type">(线上支付)</div>
                    </div>
                  </div>
                </div>
                <div class="sku-promotion-col middle">
                  <div class="sku-promotion"><span></span></div>
                </div>
                <div class="sku-total-col middle">
                 
                  <div class="sku-total"></div>
                </div>
              </div>
            </div>
            <!-- <div v-else>

            </div> -->
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { getPageOrder } from "@/api/index";
export default {
  name: "order",
  components: {},
  data() {
    return {
      num: 1,
      active: 1,
      payType: "1",
      orderList: [],
      userInfo: "",
    };
  },
  created() {
    this.getUserInfo();
    this.getPageOrder();
  },
  methods: {
    getUserInfo() {
      this.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
      console.log(this.userInfo);
    },
    getPageOrder() {
      getPageOrder(this.userInfo.userName).then((res) => {
        this.orderList = res.data.data;
        console.log(this.orderList);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.order-box {
  // display: flex;
  max-width: 1200px;
  min-height: 485px;
  margin: 32px auto;
  padding: 24px;
  border: 1px solid #eaeaea;
  background: #fff;
  .header {
    font-size: 18px;
    color: #333;
    width: 100%;
    font-weight: 700;
    margin: 16px 0 34px 0;
    position: relative;
    .order-steps {
      position: absolute;
      width: 360px;
      right: 0;
      top: -10px;
      .el-steps {
        font-weight: 400;
        justify-content: center;
        /deep/.is-finish {
          color: #003e89;
          border-color: #003e89;
          .el-step__icon {
            background: #003e89;
            color: #fff;
          }
        }

        /deep/.el-step__title {
          font-size: 12px;
          line-height: 26px;
        }
      }
    }
  }
  .content {
    .order-header {
      height: 50px;
      color: #333;
      line-height: 50px;
      font-size: 14px;
      background: #f2f9fe;
      display: flex;
      padding-left: 16px;
      .sku-title {
        width: 42.5%;
        text-align: center;
      }
      .sku-price {
        width: 14.3%;
        text-align: center;
        
      }

      .sku-qty {
        width: 14.3%;
        text-align: center;
      }
      .sku-promotion {
        width: 14.3%;
        text-align: center;
      }
      .sku-total {
        width: 14.3%;
        text-align: center;
      }
    }
    .store-list {
      margin-top: 8px;
      // padding-left: 16px;
      color: #333;
      // background: #f2f9ff;
      border: 1px solid #dee0e1;
      .middle {
        display: flex;

        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-right: 1px solid #d3eafa;
        align-items: center;
        .order-status {
          height: 100%;
          padding: 10px 0;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          font-size: 14px;
          font-weight: 700;
          .order-payment-type {
            padding: 3px 0;
            color: #999;
            font-weight: 400;
          }
        }
      }
      .title {
        padding: 13px;
        color: #333;
       background-color: rgb(243, 243, 243);
        border-bottom: none;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        .order-no {
          font-size: 14px;
        }
        .order-created-time {
          font-size: 14px;
          color: #999;
          padding: 0 16px;
        }
      }

      .store-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 14px;

        .sku-title-col {
          width: 42.5%;
          .sku-title {
            display: flex;
            padding: 12px 15px;
            border-right: 1px solid #d3eafa;
            .sku-image {
              -ms-flex-negative: 0;
              flex-shrink: 0;
              width: 100px;
              height: 67px;
              img {
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
              }
            }
            .basic-info {
              display: flex;
              margin-left: 12px;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;
              justify-content: space-between;
              padding-right: 20px;
              .sku-name {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
              }
              .sku-description {
                color: #999;
                line-height: 20px;
                font-size: 14px;
              }
              .sku-price-qty {
                display: none;
              }
              .sku-name-wrap {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
              }
            }
          }
        }
        .sku-price-col {
          width: 14.3%;
          text-align: center;
        }
        .sku-qty-col {
          width: 14.3%;
          text-align: center;
          .el-input-number--mini {
            width: 80%;
            line-height: 26px;
          }
        }
        .sku-promotion-col {
          width: 14.3%;
          text-align: center;
        }
        .sku-total-col {
          width: 14.3%;
          text-align: center;
          color: red;
          font-weight: 700;
          .label {
            display: none;
          }
        }
      }
    }
    .select-payment-method {
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background: #f2f9ff;
      margin-top: 16px;
      padding: 0 16px;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      height: 85px;
      .left-part {
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .title {
          font-size: 16px;
          font-weight: 700;
          color: #333;
        }
        .tip {
          font-size: 12px;
          color: #999;
          margin-left: 16px;
        }
      }
      .right-part {
        display: flex;
        .el-radio {
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          // align-items: center;
          width: 125px;
          height: 40px;
          background: #fff;
          border: 2px solid #b1b1b1;
        }
      }
    }
    .product-total {
      padding: 16px 0 12px;
      background: #fff;
      .content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        .content-right {
          padding-top: 35px;
          .price-label {
            font-size: 16px;
            color: #333;
            margin-bottom: 16px;
            display: flex;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            text-align: right;
            line-height: 22px;
            .value {
              width: 115px;
              font-weight: 700;
            }
          }
          .total-payment {
            margin-top: 25px;
            margin-bottom: 18px;
            font-size: 18px;
            font-weight: 700;
            .value {
              color: red;
            }
          }
        }
      }
    }
    .footer-bar-wrap {
      .footer-bar-pc {
        max-width: 1200px;
        margin: 0 auto;
        .confirm-btn {
          text-align: right;
          padding: 12px 0;
          .el-button {
            background: #003e89;
            border-radius: 2px;
            min-width: 120px;
            height: 40px;
            padding: 12px 2px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1050px) {
  .order-box {
    margin: 0 auto;
    .header {
      .header-title {
        display: none;
      }
      /deep/.order-steps {
        position: relative;
        width: 100%;
      }
    }
    .content {
      .order-sku-list {
        .order-header {
          display: none;
        }
        .store-list {
          border: none;
          background: #fff;
          margin-top: 0;
          padding: 0;
          .middle {
            display: flex;
            flex-direction: initial;
            align-items: center;
            border-right: 0px;
          }
           .title {
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    white-space: nowrap;
    margin: 0 -16px 16px -16px;
    padding: 0 16px;
    height: 36px;
    line-height: 36px;
    color: #333;
    background: #edf7ff;
        .order-no {
          font-size: 12px;
           
        }
        .order-created-time {
            display: none;
          font-size: 14px;
          color: #999;
          padding: 0 16px;
        }
      }
          .store-item {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            margin-bottom: 22px;
            border: none;
            .sku-title-col {
              width: 100%;
              background: #fff;
              margin-bottom: 8px;
              .sku-title {
                display: flex;
                padding: 0.75rem 0;
                border-right: 0.0625rem;
                .basic-info {
                  .sku-price-qty {
                    display: block;
                    margin-top: 8px;
                    width: 100%;
                    display: flex;
                    line-height: 20px;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                  }
                }
              }
            }
            .sku-price-col {
              display: none;
            }
            .sku-qty-col {
              display: none;
            }
            .sku-total-col {
              display: flex;
              -webkit-box-pack: end;
              -ms-flex-pack: end;
              justify-content: flex-end;
              // padding: 0 16px;
              width: 100%;
              text-align: right;
              color: red;
              font-weight: 700;
              .label {
                display: block;
                margin-right: 16px;
                -ms-flex-negative: 0;
                flex-shrink: 0;
                font-weight: 700;
                line-height: 20px;
              }
            }
            .sku-list {
              width: 100%;
              padding: 16px 16px 0;
              margin-bottom: 8px;
              background: #f2f9ff;
            }
          }
        }
        .select-payment-method {
          margin-top: 32px;
          padding: 16px;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          height: unset;
          .left-part {
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            width: 100%;
            align-items: center;
            .title {
              font-size: 16px;
              font-weight: 700;
            }
            .tip {
              font-size: 12px;
              margin-left: 8px;
            }
          }
          .right-part {
            display: flex;
            width: 100%;
            margin-top: 18px;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
          }
        }
      }
    }
  }
}
</style>